import { Container } from "./styles";
import heitorCarlos from "../../assets/HeitorCarlos.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Sobre Mim</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          Profissional Product Owner com 5 anos de experiência, sou especialista em transformar ideias em produtos de sucesso por meio de uma combinação estratégica de visão de mercado, 
          habilidades técnicas e liderança eficaz. Minha trajetória inclui a gestão de ciclos completos de desenvolvimento de produtos, desde a concepção e definição de requisitos 
          até o lançamento e otimização contínua.
          </p>
        </ScrollAnimation>
       
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          <br />Estou sempre em busca de novas oportunidades para inovar e agregar valor, utilizando minha expertise para enfrentar desafios e gerar resultados tangíveis. <br />
          Vamos conectar e explorar como posso contribuir para o sucesso da sua equipe e do seu produto.</p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={heitorCarlos} alt="Heitor Carlos" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}

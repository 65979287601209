import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Contato</h2>
        <p>Vamos tomar um café com pão de queijo e conversar sobre seu projeto? </p>
      </header>
      <div className="contacts">
        <div>
        <a href="mailto:contato@heitorcarlos.me"><img src={emailIcon} alt="Email" /></a> 
          <a href="mailto:contato@heitorcarlos.me">contato@heitorcarlos.me</a>
        </div>
        <div>
        <a href="tel:+5531972089987"><img src={phoneIcon} alt="Phone No" /></a>
          <a href="tel:+5531972089987">(+55) 31972089987</a>
        </div>  
      </div>
    </Container>
  )
}
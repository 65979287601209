import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import servicos from '../../assets/Servicos.svg'
import Gestao from '../../assets/gestao-proj.gif'
import Dev from '../../assets/development.gif'
import Trans from '../../assets/trans-dig.gif'

export function Service() {
  return (
    <Container id="servicos">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Serviços</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>        
          <ul className="tech-list">
            <li><img src={Gestao} alt="Gestão de Projetos" width="50px"/></li><li>Gestão de Projetos</li>
          </ul>
          <ul className="tech-list">
            <li><img src={Dev} alt="Desenvolvimento de Software" width="50px"/></li><li>Desenvolvimento de Software</li>
          </ul> <br />
          <ul className="tech-list">
            <li><img src={Trans} alt="Transformação Digital" width="50px"/></li><li>Transformação Digital</li>
          </ul>     <br />     
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={servicos} alt="Serviços Prestados" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}
